@import url('https://fonts.googleapis.com/css?family=Cardo');

html {
  scroll-behavior: smooth;
}

.App {
  width: 100vw;
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #6dd5ed, #2193b0);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #6dd5ed, #2193b0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: antiquewhite;
  font-family: 'Cardo', serif;
  font-size: 1.5em;
}

a {
  color: antiquewhite
}

.nav-link:hover {
  text-decoration: underline;
}

.Header {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 5vh;
  padding-bottom: 3.5%;
  background: #2c3e50;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #3498db, #2c3e50);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #3498db, #2c3e50); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: fixed;
  z-index: 2;
  opacity: 0.5;
}

.banner {
  background-image: url(./Assets/Banner.jpg);
  background-size: cover;
  height: 100vh;
}

.banner-text {
  margin: 0 auto;
  text-align: center;
  z-index: 2;
  color: #FF9933;
  text-shadow: 2px 2px white
}

.first {
  font-size: 1.5em;
  -webkit-animation: slide-in-elliptic-top-fwd 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-elliptic-top-fwd 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.second {
  font-size: 4em;
  -webkit-animation: slide-in-elliptic-bottom-fwd 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-elliptic-bottom-fwd 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 .banner > h3{
  padding-top: 25%;
 }

.nav {
  float: right;
  padding-right: 8%;
  padding-top: 1%;
  text-shadow: none;
}

.nav li {
  display: inline;
  list-style: none;
  padding: 1em;
}

.nav a {
  text-decoration: none;
}

.name { 
  float: left;
  padding-left: 8%;
  color: antiquewhite;
  text-shadow: none;
}

.about {
  width: 50%;
  padding-top: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1em;
  margin: 0 auto;
}

.about h3 {
  text-align: center;
  font-size: 2em;
}

.portrait {
  width: 200px;
  border-radius: 100%;
}

.skills h3 {
  text-align: center;
  font-size: 2em;
}

.skills {
  margin-bottom: 1em;
}

.icons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 1.5em;
  grid-row-gap: 1em;
}

.icon {
  width: 4rem;
  height: 4rem;
}

.icon:hover {
  -webkit-animation: shadow-pop-br 0.3s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
	animation: shadow-pop-br 0.3s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

.row1 {
  grid-row: 1;
}

.row2 {
  grid-row: 2;
}

.row3 {
  grid-row: 3;
}

.column1 {
  grid-column: 1;
}

.column2 {
  grid-column: 2;
}

.column3 {
  grid-column: 3;
}

.column4 {
  grid-column: 4;
}

.projects {
  width: 100%;
  display: grid;
  grid-template-rows:repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 0;
  margin-bottom: 0;
  grid-column-gap: 2%;
}

.projects .column1 {
  padding-left: 3%;
}

.projects .column2 {
  padding-right: 3%;
}
.screen {
  width: 75%;
  border: #FF9933 2px solid;
  border-radius: 10%;
  margin-left: 5%;
}

.projects-header {
  text-align: center;
  font-size: 2em;
}

.screen:hover {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	animation: heartbeat 1.5s ease-in-out infinite both;
}

.linkedin {
  float: left;
}

.github {
  float: right;
}

.iconContainer {
  margin-top: 3%;
}

.linkedin:hover {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	animation: heartbeat 1.5s ease-in-out infinite both;
}

.github:hover {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	animation: heartbeat 1.5s ease-in-out infinite both;
}

.footer {
  text-align: center;
  font-size: 0.5em;
  padding-bottom: 0.5%;
}

.repo {
  padding-left: 40%;
}

.repo:hover {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	animation: heartbeat 1.5s ease-in-out infinite both;
}

@media screen and (max-width: 480px) and (min-width: 320px) {
  .App {
    font-size: 1em;
  }
  .Header {
    position: relative;
  }
  .first {
    font-size: 1.25em;
  }
  .second {
    font-size: 3em;
  }
  .banner {
    width: 100%;
    background-size: cover;
    height: 75vh;
    background-position: center;
  }

  .about h3 { 
    font-size: 1.5em;
  }

  .icon {
    height: 2rem;
    width: 2rem;
  }
  .skills h3 {
    font-size: 1.5em;
  }
  .projects-header {
    font-size: 1.5em;
  }
  .portrait {
    width: 7em;
  }
  .projects {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }

  .m-row1 {
    grid-column: 1;
    grid-row: 1;
  }
  .m-row2 {
    grid-column: 1;
    grid-row: 2;
    margin-bottom: 1%;
    padding-left: 3%;
  }
  .m-row3 {
    grid-column: 1;
    grid-row: 3;
    grid-row-gap: 0;
    padding-bottom: none;
  }
  .m-row4 {
    margin-top: -9%;
    grid-column: 1;
    grid-row: 4;
    padding-left: 3%;
  }
  .p-link {
    margin-left: 5%;
  }

  .projects > .icon {
    grid-row-gap: none;
  }

  .icon:hover {
    animation: none;
  }

  .github:hover {
    animation: none;
  }

  .linkedin:hover {
    animation: none;
  }

  .r-link {
    margin-left: 1.5%;
  }

  .repo {
    padding: none;
  }

  .about {
    width: 100%;
  }

  .screen:hover {
    animation: none;
  }

  .Header {
    font-size: 1em;
  }

  .nav li {
    padding: 0;
  }

  .nav {
    padding: 0;
    font-size: 1em;
    padding-right: 2%;
  }

  .name {
    padding: 1%;
    font-size: 1em;
  }

  .nav-link {
    font-size: 0.75em;
    padding: 1.7%;
  }

  #home-link {
    display: none;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-5-15 16:56:39
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
 @-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

/**
 * ----------------------------------------
 * animation shadow-pop-br
 * ----------------------------------------
 */
 @-webkit-keyframes shadow-pop-br {
  0% {
    box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
  100% {
    box-shadow: 1px 1px #3e3e3e, 2px 2px #3e3e3e, 3px 3px #3e3e3e, 4px 4px #3e3e3e, 5px 5px #3e3e3e, 6px 6px #3e3e3e, 7px 7px #3e3e3e, 8px 8px #3e3e3e;
    -webkit-transform: translateX(-8px) translateY(-8px);
            transform: translateX(-8px) translateY(-8px);
  }
}
@keyframes shadow-pop-br {
  0% {
    box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
  100% {
    box-shadow: 1px 1px #3e3e3e, 2px 2px #3e3e3e, 3px 3px #3e3e3e, 4px 4px #3e3e3e, 5px 5px #3e3e3e, 6px 6px #3e3e3e, 7px 7px #3e3e3e, 8px 8px #3e3e3e;
    -webkit-transform: translateX(-8px) translateY(-8px);
            transform: translateX(-8px) translateY(-8px);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-5-16 13:39:22
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
            transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
            transform-origin: 50% 1400px;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
            transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
            transform-origin: 50% 1400px;
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-5-16 13:41:4
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-elliptic-bottom-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-elliptic-bottom-fwd {
  0% {
    -webkit-transform: translateY(600px) rotateX(30deg) scale(0);
            transform: translateY(600px) rotateX(30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% -1400px;
            transform-origin: 50% -1400px;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-bottom-fwd {
  0% {
    -webkit-transform: translateY(600px) rotateX(30deg) scale(0);
            transform: translateY(600px) rotateX(30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% -1400px;
            transform-origin: 50% -1400px;
    opacity: 1;
  }
}
